<template>
  <div class="media-player" style="margin-top: 2em;">
    <BField label="User id:" label-position="on-border" style="text-align: left">
      <BInput v-model="userId" placeholder="ingresa un user id" />
    </BField>
    <div class="buttons">
      <BButton type="is-primary" outlined @click="onClickApplyUserId">
        Aplicar user id
      </BButton>
    </div>
    <div class="media-player__container">
      <MediaPlayer ref="mediaPlayer" @play-change="onPlayChange" :user-id="userId" @change-user-id="onChangeUserId" />
    </div>
    <div v-if="currentPlay.payloadData">
      <p>grupo: {{ currentPlay.payloadData.group_id }}</p>
      <p>grupo de playlists: {{ currentPlay.payloadData.group_playlist_id }}</p>
      <p>playlist: {{ currentPlay.payloadData.playlist_id }}</p>
      <p>jugada: {{ currentPlay.playId }}</p>
      <p>total de jugadas de la playlist: {{ currentPlay.payloadData.total_plays }}</p>
      <p>número de jugada en la playlist: {{ currentPlay.payloadData.play_number }}</p>
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaPlayerV2',
  components: {
    BButton: async () => {
      const { BButton } = await import('buefy/dist/esm/button');
      return BButton;
    },
    BInput: async () => {
      const { BInput } = await import('buefy/dist/esm/input');
      return BInput;
    },
    BField: async () => {
      const { BField } = await import('buefy/dist/esm/field');
      return BField;
    },
    MediaPlayer: () => import('@/components/Sections/MediaPlayer-v2/Main'),
  },
  data() {
    return {
      currentPlay: {},
      userId: '',
    };
  },
  methods: {
    onPlayChange(play) {
      this.currentPlay = play;
    },
    onClickApplyUserId() {
      this.$refs.mediaPlayer.fillPlaySeekerPlays(true);
    },
    onChangeUserId(userId) {
      this.userId = userId;
    },
  },
  mounted() {
    this.userId = this.userId.trim() || window.localStorage.getItem('playSeekerUserId') || '';
  },
};
</script>

<style scoped lang="scss">
.media-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__container {
    width: 100%;
    max-width: 1080px;
  }

  &__play-id {
    display: flex;
    margin: 1rem 0;

    & span {
      margin: 0 0.5rem;
    }
  }
}
</style>
