import { render, staticRenderFns } from "./MediaPlayerV2.vue?vue&type=template&id=7d2819ff&scoped=true&"
import script from "./MediaPlayerV2.vue?vue&type=script&lang=js&"
export * from "./MediaPlayerV2.vue?vue&type=script&lang=js&"
import style0 from "./MediaPlayerV2.vue?vue&type=style&index=0&id=7d2819ff&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d2819ff",
  null
  
)

export default component.exports